import FlexibleSections from "@/components/FlexibleSections";
import Layout from "@/components/Layout";
import { PageContext } from "@/context/PageContext";
import { useSubscribedReroute } from "@/hooks/useSubscribedReroute";
import { PAGE_HOME } from "@/lib/contextConstants";
import {
  getFAQs,
  getFilteredContent,
  getGeneralTestimonials,
  getGlobalContent,
  getPage,
  getPlans,
  getShowreel,
  getTopics,
  getEventLogo,
  getSearchLinks,
} from "@/lib/requests";
import { BuilderComponent, builder, useIsPreviewing } from "@builder.io/react";
import { FORCE_SHOW_BUILDER } from "lib/constants";
import { useEffect, useState } from "react";

export default function Home({
  builderIoPage,
  cards,
  preview,
  faqs,
  globalContent,
  placeholders,
  searchLinks,
  plans,
  page,
  sections,
  showreel,
  testimonials,
  eventLogo,
}) {
  const loaded = useSubscribedReroute();
  const [isLoaded, setIsLoaded] = useState(false);

  const isOnBuilderPreviewMode = useIsPreviewing();
  const showLocalBuilder = FORCE_SHOW_BUILDER && builderIoPage;
  const isPublishedBuilder =
    !!builderIoPage && builderIoPage?.published === "published";

  const shouldShowBuilder =
    isPublishedBuilder || isOnBuilderPreviewMode || showLocalBuilder;

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  return (
    <Layout
      eventLogo={eventLogo}
      preview={preview}
      placeholders={placeholders}
      searchLinks={searchLinks}
      headerCta={globalContent.headerCtaContent}
    >
      {loaded && (
        <PageContext.Provider
          value={{
            page: !!builderIoPage ? "builder" : PAGE_HOME,
            sectionCount: 0,
          }}
        >
          {isLoaded && (
            <>
              {shouldShowBuilder ? (
                <BuilderComponent
                  content={builderIoPage}
                  model="page"
                  data={{
                    faqs: faqs,
                  }}
                />
              ) : (
                <FlexibleSections
                  data={sections}
                  cards={cards}
                  faqs={faqs}
                  globalContent={globalContent}
                  placeholders={placeholders}
                  plans={plans}
                  showreel={showreel}
                  testimonials={testimonials}
                  featuredImage={page.featuredImage.node}
                  carouselFadeColor="white"
                />
              )}
            </>
          )}
        </PageContext.Provider>
      )}
    </Layout>
  );
}

export async function getStaticProps({ preview = false, previewData }) {
  const builderIoPage = await builder
    .get("page", {
      userAttributes: {
        urlPath: "/",
      },
      staleCacheSeconds: 30,
    })
    .toPromise();

  const eventLogo = await getEventLogo();
  const searchLinks = await getSearchLinks();
  const faqs = (await getFAQs({ isServer: true })) || [];

  const pageExistsInBuilder =
    builderIoPage &&
    builderIoPage.data?.url &&
    builderIoPage?.published !== "archived";

  if (pageExistsInBuilder) {
    const builderIoGlobalContent = await getGlobalContent();
    const fallbacks = await builder.getAll("fallback-data");

    return {
      props: {
        builderIoPage,
        eventLogo,
        searchLinks,
        faqs,
        globalContent:
          builderIoGlobalContent?.acfOptionsGlobalContent
            ?.optionsGlobalContent || null,
        fallbacks,
      },
    };
  }

  const { page, acfOptionsGlobalContent, acfOptionsPlaceholders } =
    await getPage({
      slug: "/home",
      preview,
      previewData,
    });

  const plans = (await getPlans({ isServer: true })) || [];
  const testimonials = (await getGeneralTestimonials({ isServer: true })) || [];
  const showreel = await getShowreel({ isServer: true });
  const topics = (await getTopics({ isServer: true })) || [];

  const cards = await getFilteredContent({
    filters: {
      topics: [topics[0].topicId],
    },
    isServer: true,
  });

  return {
    props: {
      preview,
      previewData: previewData ?? null,
      page: page,
      globalContent: acfOptionsGlobalContent?.optionsGlobalContent,
      placeholders: acfOptionsPlaceholders?.optionsPlaceholderImages,
      searchLinks,
      sections: page?.customPageSections?.flexibleSections,
      seo: page?.seo,
      cards,
      faqs,
      plans,
      showreel,
      testimonials,
      eventLogo,
    },
  };
}
