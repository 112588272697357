/**
 * The `useSubscribedReroute` function checks the user's subscription status and redirects them based
 * on specified options.
 * @param [options] - The `useSubscribedReroute` function takes an `options` object as a parameter with
 * the following properties:
 *   @param [options.redirectToPayment] - A boolean value indicating whether to redirect the user to the
 *   payment page if they are not subscribed.
 *   @param [options.redirectToRegister] - A boolean value indicating whether to redirect the user to the
 *   register page if they are logged in.
 *   @param [options.disabled] - A boolean value indicating whether the rerouting logic should be disabled.
 * 
 * @returns The `useSubscribedReroute` custom hook returns a boolean value indicating whether the
 * rerouting logic has been completed or not. It returns `true` when the rerouting conditions have been
 * met and the appropriate redirection has been done, and `false` otherwise.
 */

import { useUser } from "@/context/UserContext";
import { ROUTES } from "@/lib/routes";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";

export function useSubscribedReroute(
  options = {
    redirectToPayment: false,
    redirectToRegister: false,
    disabled: false,
  }
) {
  const { user } = useUser();
  const [loaded, setLoaded] = useState(false);
  const router = useRouter();

  useEffect(() => {
    if (user === null) return;
    if (options?.disabled) {
      setLoaded(true);
      return;
    }
    if (!user || !user?.subscription_active) {
      if (options?.redirectToPayment && user && !user?.subscription_active) {
        router.replace(ROUTES.MEMBERSHIP_PAYMENT);
        return;
      } else if (options?.redirectToRegister && !user) {
        router.replace(ROUTES.MEMBERSHIP_CHECKOUT);
        return;
      }
      setLoaded(true);
      return;
    } else {
      router.replace(ROUTES.LIBRARY);
    }
    return () => setLoaded(false);
  }, [user, options]);

  return loaded;
}
